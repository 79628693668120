@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply relative text-base text-body bg-gray;
        font-family: 'Barlow Semi Condensed', sans-serif;
    }
}

@layer components {
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
}

/* third-party libraries CSS */

*::-webkit-scrollbar {
    @apply cursor-pointer w-1.5 h-1.5 lg:w-2.5 lg:h-2.5;
}

*::-webkit-scrollbar-track {
    @apply bg-stroke dark:bg-strokedark;
}

*::-webkit-scrollbar-thumb {
    @apply rounded-full bg-body dark:bg-stroke/30 hover:bg-strokedark dark:hover:bg-stroke/40;
}

.custom-input-date::-webkit-calendar-picker-indicator {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
}

[x-cloak] {
    display: none !important;
}

button:focus {
    box-shadow: none !important;
}
