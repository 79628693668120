/**
 * @license
 *
 * Font Family: Barlow Semi Condensed
 * Designed by: eremy Tribby
 * URL: https://fonts.google.com/specimen/Barlow+Semi+Condensed
 *
 * Font Styles:
 * Barlow Semi Condensed Light
 * Barlow Semi Condensed Light Italic
 * Barlow Semi Condensed Regular
 * Barlow Semi Condensed Italic
 * Barlow Semi Condensed Medium
 * Barlow Semi Condensed Medium Italic
 * Barlow Semi Condensed Bold
 * Barlow Semi Condensed Bold Italic
 * Barlow Semi Condensed Black
 * Barlow Semi Condensed Black Italic
 *
*/

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Italic.ttf') format('truetype');
    font-weight: 400;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-display: swap;
    font-style: italic;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-Black.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow Semi Condensed';
    src: url('../fonts/BarlowSemiCondensed/BarlowSemiCondensed-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-display: swap;
    font-style: italic;
}
